<template>
  <div class="pa-2">
    <v-card>
      <v-card-title>
        <div v-if="data.id">
          {{data.name}} ({{data.id}})
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="$router.go(-1)" class="ma-1"> Назад </v-btn>
          <v-btn @click="submit()" color="primary" class="ma-1"
            >Сохранить</v-btn
          >
          <v-btn
            v-if="$route.params.id"
            @click="remove(api, $route.params.id)"
            color="error"
            class="ma-1"
            >Удалить</v-btn
          >
        </div>
      </v-card-title>
      <v-card-text>
        <a-form-from-model
          :value="data"
          :model="model"
          :errors="errors"
          @input="data = $event"
          @validate="validate($event)"
        ></a-form-from-model>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import getForm from "@/share/components/libs/getForm";
import submitForm from "@/share/components/libs/submitForm";
import genModel from "@/share/components/libs/genModel";
import removeEl from "@/share/components/libs/removeEl";

export default {
  mixins: [genModel, getForm, submitForm, removeEl],
  created() {
    this.updateData(this.$route.params.id);
  },
  computed: {
    api() {
      return this.$root.api[this.$route.params.api];
    },
    model() {
      this.$root.appName = this.getConfigView(this.viewName).name;
      return this.modelForm;
    },
  },
  methods: {
    afterRemove(data) {
      this.$root.$emit("show-info", {
        text: "Элемент удален",
      });
      this.$router.go(-1);
    },
  },
};
</script>